<template>
    <div class="aboutUs-container">
        <header-nav></header-nav>

        <div class="main">
            <section ref="section-1" class="section headeImg">
                <div class="contentBox">
                    <p class="h1p">智慧园区后勤整体解决方案</p>
                    <p class="h5p">天诚通过制定合理的管理制度、采用现代化的管理手段、提供优质的服务以及有效的沟通协调，实现企业园区后勤数字化管理</p>
                </div>
            </section>

            <section ref="section-2" class="section businessPainPoint">
                <div class="contentBox">
                    <div class="solutionName">业务痛点</div>
                    <div class="list">
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem1.png" alt="">
                                <p>人力成本逐年上升</p>
                            </div>
                            <div class="contont">
                                随着园区宿舍规模的扩大，需要更多的管理人员、保安和维修人员，这导致人力成本不断增加，给企业带来了沉重的负担。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem2.png" alt="">
                                <p>管理效率低下</p>
                            </div>
                            <div class="contont">
                                传统的管理模式依赖于人工进行信息录入、查询、统计和分析，这些过程不仅耗时而且容易出错，导致管理效率低下。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem3.png" alt="">
                                <p>安全隐患突出</p>
                            </div>
                            <div class="contont">
                                随着园区宿舍规模的扩大，需要更多的管理人员、保安和维修人员，这导致人力成本不断增加，给企业带来了沉重的负担。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem4.png" alt="">
                                <p>管理方式单一</p>
                            </div>
                            <div class="contont">
                                传统的管理模式依赖于人工进行信息录入、查询、统计和分析，这些过程不仅耗时而且容易出错，导致管理效率低下。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem5.png" alt="">
                                <p>协同管理难</p>
                            </div>
                            <div class="contont">
                                随着园区宿舍规模的扩大，需要更多的管理人员、保安和维修人员，这导致人力成本不断增加，给企业带来了沉重的负担。
                            </div>
                        </div>
                        <div class="item">
                            <div class="titieWarp">
                                <img class="icon" src="../assets/images/park/listItem6.png" alt="">
                                <p>报修服务与反馈流程繁琐</p>
                            </div>
                            <div class="contont">
                                传统的管理模式依赖于人工进行信息录入、查询、统计和分析，这些过程不仅耗时而且容易出错，导致管理效率低下。
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref="section-3" class="section solution">
                <div class="sections">
                    <div class="contentBox"> 
                        <div class="solutionName" style="color: #FAFBFC;">企业园区后勤管理解决方案</div>
                        <div class="list">
                            <div class="item">
                                <div class="titieWarp">
                                    <p>人员管理</p>
                                </div>
                                <div class="contont">
                                    内/外部人员管理，以及授权管理,可对接企业内部第三方人事系统
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>办公管理</p>
                                </div>
                                <div class="contont">
                                    以房态宫格展示办公室等数据，功能室预约后下发权限，到期删除
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>智慧房态</p>
                                </div>
                                <div class="contont">
                                    查看人员入住情况、历史住宿信息，可查看房间的出入记录等信息
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>调换宿舍</p>
                                </div>
                                <div class="contont">
                                    自动将对应人员旧宿舍授权删除，并下发新宿舍授权
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>设备管理</p>
                                </div>
                                <div class="contont">
                                    新增设备方便，可录入指纹、身份证等信息，密码下发一步到位
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>考勤管理</p>
                                </div>
                                <div class="contont">
                                    灵活配置排班规则，选择不同区域设备，考勤人员选择设备进行打卡
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>通行管理</p>
                                </div>
                                <div class="contont">
                                    不同应用场景，对门锁设置不同规则，外来人员可快速下发权限
                                </div>
                            </div>
                            <div class="item">
                                <div class="titieWarp">
                                    <p>资产管理</p>
                                </div>
                                <div class="contont">
                                    资产入库、资产领用等功能可做到资产查看，资产盘点新增盘点单
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>

            <section ref="section-4" class="section framework">
                <div class="contentBox">
                    <div class="solutionName">产品模块架构</div>
                    <img class="infoImg" src="../assets/images/park/framework.png" alt="">
                </div>
            </section>

            <section ref="section-5" class="section flow">
                <div class="contentBox">
                    <div class="solutionName">园区后勤管理——员工生命周期</div>
                    <img class="infoImg" src="../assets/images/park/flow.png" alt="">
                </div>
            </section>

            <section ref="section-6" class="section advantage">
                <div class="contentBox">
                    <div class="solutionName">系统功能优势</div>
                    <div class="title">具有大屏端、PC端、手机端三大终端交互入口，面向园区决策者、园区后勤管理者、园区职工、园区访客等不同用户群体提供服务.</div>
                    <div class="infoTable">
                        <img class="advantageTableImg" src="../assets/images/park/tableImg.jpg" alt="">
                        <div class="tableWarp">
                            <div class="item">
                                <img class="icon" src="../assets/images/park/table1.png" alt="">
                                <p>提升办公协同效率和通行效率</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/park/table2.png" alt="">
                                <p>提升业务联动能力</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/park/table3.png" alt="">
                                <p>打造统一的平台</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/park/table4.png" alt="">
                                <p>降低人力成本</p>
                            </div>
                            <div class="item">
                                <img class="icon" src="../assets/images/park/table5.png" alt="">
                                <p>提升安全与应急响应能力</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-nav></footer-nav>
        <!-- 定位栏 -->
        <location-column></location-column>
    </div>
</template>
  
<script>
  import headerNav from "@/components/header-nav";
  import footerNav from "@/components/footer-nav";
  export default {
    name: "cooperation",
    components: { headerNav, footerNav },
    metaInfo: {
      meta: [
        {
          name: "keywords",
          content:
            "新巢天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
        },
        {
          name: "description",
          content:
            "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
        },
      ],
    },
    data() {
        return {
            copyDom: "",
            lastScrollTop: 0,
            scrollDirection: 'down',
            isScrolling: false,
            scrollStopTimer: null,
            scrollThrottle: null,
        };
    },
    methods: {
        getAnimationClass(section) {
            return Array.from(section.classList).find(item => item.startsWith('animationClass-'));
        },

        handleScroll() {
            this.isScrolling = true;
            clearTimeout(this.scrollStopTimer)
            this.scrollStopTimer = setTimeout(() => {
                this.isScrolling = false
            }, 150)

            if (!this.scrollThrottle) {
                this.scrollThrottle = requestAnimationFrame(() => {
                // 获取窗口滚动位置（兼容不同浏览器）
                const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

                // 保持原有方向判断逻辑
                if (Math.abs(currentScrollTop - this.lastScrollTop) > 10) {
                    this.scrollDirection = currentScrollTop > this.lastScrollTop ? 'down' : 'up';
                }
                    this.lastScrollTop = currentScrollTop;
                    this.scrollThrottle = null;
                })
            }
        },

        // 动画触发方法
        triggerAnimation(section) {
            section.classList.remove('active');   // 移除动画类以确保可以重新触发
            void section.offsetWidth; // 强制重绘，确保动画重置
            section.classList.add('active'); // 重新添加动画类以触发淡入


            // //类名不固定时使用
            // const animationClass = this.getAnimationClass(section); //获取class类名
            // section.classList.remove(`${animationClass}-active`);   // 移除动画类以确保可以重新触发
            // void section.offsetWidth; // 强制重绘，确保动画重置
            // section.classList.add(`${animationClass}-active`); // 重新添加动画类以触发淡入
            
            // // 动画结束后自动移除类
            // section.addEventListener('animationend', () => {
            //     section.classList.remove(`${animationClass}-active`);
            // }, { once: true });
        },
    },
    created() {
        
    },
    mounted() {
        document.title = this.$store.state.searchTitle;

        window.addEventListener('scroll', this.handleScroll);
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if(this.scrollDirection == 'down'){ 
                        if(this.copyDom == entry.target) return;
                        console.log("当前过渡元素",entry.target)

                        const section = entry.target;
                        this.triggerAnimation(section);
                        this.copyDom = entry.target;
                    }else{ 
                        console.log("向上滚动")
                        this.copyDom = '';
                    }
                }
            })
        },{
            threshold: 0,
            // rootMargin: "0px 0px -100px 0px" // 添加底部负边距防止过早触发
        });

        this.$nextTick(() => {
            for(let i = 1; i <= 6; i++) {
                observer.observe(this.$refs[`section-${i}`])
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
  };
</script>
  
<style scoped lang="less">
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.aboutUs-container {
    /* min-width: 1366px; */
    .main {
        width: 100%;
        padding-top: 80px;
        .section{
            border: 1px solid transparent;
            .contentBox{
                width: 1300px;
                margin: 0 auto;
                opacity: 0;
                transition: opacity 0.5s ease-out;
                .solutionName{
                    height: 28px;
                    line-height: 28px;
                    font-size: 31px;
                    color: #000103;
                    position: relative;
                    margin-top: 50px;
                    // padding-bottom: 25px;
                    // margin-bottom: 12px;
                    &::after{
                        position: absolute;
                        content: "";
                        height: 3px;
                        width: 156px;
                        background-color: #1B5CDB;
                        bottom: -30px;
                        left: calc(50% - 78px); 
                    }
                }
            }
            &.active{
                .contentBox{
                    animation: fadeIn 1.5s forwards;
                }
            }
        }
        .headeImg{
            width: 100%;
            height: 749px;
            // height: 562px;
            background-image: url("../assets/images/park/backImg.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            // &.active{
            //     .contentBox{
            //         animation: fadeIn 1s forwards;
            //     }
            // }
            .contentBox{
                height: 100%;
                padding-top:179px;
                .h1p{
                    height: 42px;
                    line-height: 42px;
                    font-size: 46px;
                    color: #F3F6FB;
                    text-align: left;
                    opacity: 0.9;
                }
                .h5p{
                    margin-top: 45px;
                    max-width: 712px;
                    height: 59px;
                    line-height: 45px;
                    font-size: 19px;
                    color: #F9FAFB;
                    text-align: left;
                    opacity: 0.8;
                }
            }
        }
        .businessPainPoint{
            .contentBox{
                width: 1300px;
                // min-height: 785px;
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 70px;
                    margin-bottom:40px;
                    .item{
                        // width: 46%;
                        // height: 177px;
                        width: 630px;
                        min-height: 153px;
                        padding: 17px 31px 0px 31px;
                        box-sizing: border-box;
                        background: #A6CCFCAD;
                        border-radius: 18px 18px 18px 18px;
                        margin-bottom: 40px;
                        .titieWarp{
                            display: flex;
                            align-items: flex-end;
                            .icon{
                                width:42px;
                                height:42px;
                                margin-right: 10px;
                            }
                            p{
                                font-size: 26px;
                                color: #000103;
                            }
                        }
                        .contont{
                            text-align:left;
                            margin-top:10px;
                            margin-left: 46px;
                            font-size: 18px;
                            color: #606060;
                            line-height:26px;
                        }
                    }
                }
            }
        }
        .sections{
            background-color: #000103E8;
        }
        .solution{
            background-image: url("../assets/images/park/system.png");
            // background-size: cover;
            background-size: 100%;
            background-position: top left;
            background-repeat: no-repeat;
            .contentBox{
                width: 1321px;
                min-height: 700px;
                border: 1px solid transparent;
                .list{
                    margin-top: 112px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .item{
                        width: 306px;
                        min-height: 192px;
                        background: #08126DAB;
                        padding: 40px 24px 16px;
                        box-sizing: border-box;
                        margin-bottom: 30px;
                        .titieWarp{
                            display: flex;
                            align-items: center;
                            p{
                                font-size: 25px;
                                color: #FAFBFC;
                                height: 23px;
                                line-height: 23px;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    content: "";
                                    height: 3px;
                                    width: 60px;
                                    background-color: #A6CCFC;
                                    border-left: 30px solid #438EF9;
                                    bottom:-18px;
                                    left: 0px; 
                                }
                            }
                        }
                        .contont{
                            margin-top: 23px;
                            text-align:left;
                            font-size: 16px;
                            color: #A5ACB8;
                            line-height:38px;
                        }
                    }
                }
            }
        }
        .framework{
            background-color: #DCEDFA;
            .contentBox{
                width:1300px;
                // min-height: 856px;
                .infoImg{
                    width:1300px;
                    // height: 642px;
                    margin-top:46px;
                    margin-bottom:60px;
                }
            }
        }
        .flow{
            background-color:#FAFBFC;
            .contentBox{
                width:1300px;
                // min-height: 860px;
                .infoImg{
                    width:1300px;
                    // height: 746px;
                    margin-top:60px;
                    margin-bottom:60px;
                }
            }
        }
        .advantage{
            background-color: #D2EBFC;
            .contentBox{
                width:1300px;
                // min-height: 1020px;
                .title{
                    width: 1300px;
                    line-height:51px;
                    font-size: 28px;
                    color: #000103;
                    text-align: left;
                    margin: 61px 0px;
                }
                .infoTable{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 76px;
                    margin-bottom:100px;
                    .advantageTableImg{
                        width: 756px;
                        // height: 530px;
                    }
                    .tableWarp{
                        width: 460px;
                        // min-height: 392px;
                        // min-height: 559px;
                        background: #F5F7FB;
                        border-radius: 24px 24px 24px 24px;
                        padding: 0px 40px;
                        box-sizing: border-box;
                        // border: 4px solid #DAE5F8;
                        .item{
                            height: 78px;
                            border-bottom: 2px dashed #C3C3C7;
                            display: flex;
                            align-items: center;
                            &:last-child{
                                border-bottom:0px;
                            }
                            .icon{
                                width: 60px;
                                height:60px;
                                margin-right: 22px;
                            }
                            p{ 
                                font-size: 19px;
                                color: #000104;
                            }
                        }
                    }
                }
            }
        }
    }
}
//   .header-content {
//     height: 160px;
//     margin: auto;
//     padding-top: 120px;
//     padding-left: 20px;
//   }
//   .title {
//     font-size: 40px;
//     margin-top: 30px;
//     font-weight: bold;
//   }
//   .contact_list {
//     margin-top: 40px;
//     width: 1200px;
//     margin: auto;
//   }
//   .line {
//     width: 80px;
//     margin: auto;
//     border-bottom: 2px solid #d34100;
//     margin-top: 10px;
//   }
//   .content {
//     font-size: 15px;
//     padding-top: 20px;
//     color: #333333;
//     margin: auto;
//     text-align: left;
//     letter-spacing: 2px;
//     line-height: 25px;
//   }

@media screen and (max-width: 768px) {
    .main {
      padding-top: 50px !important;
    }
    .main-header {
      height: 166px !important;
    }
    .solutionName {
      font-size: 20px !important;
      margin-top: 20px !important;
      &::after{
        height: 3px !important;
        width: 83px !important;
        bottom: -10px !important;
        left: calc(50% - 41px) !important; 
      }
    }
    .headeImg{
        height: 166px !important;
        .contentBox{
            padding-top:25px !important;
            .h1p{
                height: 42px !important;
                line-height: 42px !important;
                font-size: 24px !important;
                padding-left: 10px;
            }
            .h5p{
                margin-top: 5px !important;
                max-width: 712px !important;
                height: 59px !important;
                line-height: 22px !important;
                font-size: 14px !important;
                padding-left: 10px;
            }
        }
    }
    .contentBox{
        width: 100% !important;
    }

    .businessPainPoint{
        .contentBox{
            width: 100% !important;
            .list{
                margin-top: 40px !important;
                margin-bottom:10px !important;
                .item{
                    width: 100% !important;
                    min-height: 123px !important;
                    padding: 10px 16px 0px 20px !important;
                    margin: 0px 30px !important;
                    margin-bottom: 20px !important;
                    .titieWarp{
                        .icon{
                            width:30px !important;
                            height:30px !important;
                            margin-right: 10px !important;
                        }
                        p{
                            font-size: 16px !important;
                        }
                    }
                    .contont{
                        margin-top:6px !important;
                        margin-left: 30px !important;
                        font-size: 14px !important;
                        line-height:20px !important;
                    }
                }
            }
        }
    }
    .solution{
        .contentBox{
            width: 100% !important;
            min-height: 700px !important;
            .list{
                margin-top: 40px !important;
                .item{
                    width: 100% !important;
                    min-height: 123px !important;
                    padding: 20px 18px 6px !important;
                    margin-bottom: 30px !important;
                    margin: 0px 30px 30px !important;
                    .titieWarp{
                        p{
                            font-size: 16px !important;
                            height: 23px !important;
                            line-height: 23px !important;
                            &::after{
                                height: 3px !important;
                                width: 40px !important;
                                bottom:-6px !important;
                                border-left: 20px solid #438EF9 !important;
                                left: 0px !important;
                            }
                        }
                    }
                    .contont{
                        margin-top: 15px !important;
                        font-size: 14px !important;
                        line-height:25px !important;
                    }
                }
            }
        }
    }
    .framework{
        .contentBox{
            width: 100% !important;
            .infoImg{
                width: 100% !important;
                margin-top: 40px !important;
                margin-bottom: 34px !important;
            }
        }
    }
    .flow{
        .contentBox{
            width: 100% !important;
            .infoImg{
                width: 100% !important;
                margin-top: 16px !important;
                margin-bottom: 34px !important;
            }
        }
    }
    .advantage{
        .contentBox{
            width: 100% !important;
            .title{
                width: 100% !important;
                line-height:26px !important;
                font-size: 16px !important;
                margin: 26px 0px !important;
                padding: 0 20px !important;
            }
            .infoTable{
                flex-direction: column;
                margin-top: 24px !important;
                margin-bottom:40px !important;
                .advantageTableImg{
                    width: 100% !important;
                    margin-bottom:30px !important;
                    padding: 0px 10px !important;
                }
                .tableWarp{
                    // width:100% !important;
                    width:calc(100% - 40px) !important;
                    border-radius: 16px 16px 16px 16px !important;
                    padding: 0px 10px !important;
                    .item{
                        height: 50px !important;
                        border-bottom: 1px dashed #C3C3C7 !important;
                        &:last-child{
                            border-bottom:0px !important;
                        }
                        .icon{
                            width: 40px !important;
                            height:40px !important;
                            margin-right:14px !important;
                        }
                        p{ 
                            font-size: 15px !important;
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(150px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes fadeOn {
    from {
        opacity: 0;
        transform: translateX(-150px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}
</style>